import React, { Component } from "react"
import PropTypes from "prop-types"
import Header from "./Header"
import Footer from "./Footer"
import "@fortawesome/fontawesome-free/css/all.min.css"
import "bootstrap-css-only/css/bootstrap.min.css"
import "mdbreact/dist/css/mdb.css"
import "../../resources/css/style.css"
import "../../resources/css/responsive.css"
class Layout extends React.Component {
  render() {
    return (
      <>
        <Header />
        {this.props.children}
        <Footer />
      </>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}
export default Layout
