import React from "react"
import { MDBContainer, MDBRow, MDBCol, MDBTypography, MDBIcon } from "mdbreact"
import Logo from "../../resources/images/footer-logo.svg"
import { Link } from "gatsby"
const Footer = () => {
  return (
    <div>
      <footer className="desktop">
        <MDBContainer>
          <MDBRow>
            <MDBCol md="4" sm="12" xs="12">
              <MDBTypography tag="p">©2021 Copyright Vicreation</MDBTypography>
            </MDBCol>
            <MDBCol md="4" sm="12" xs="12">
              <Link
                target="_blank"
                to="https://vicreation.de/"
                style={{ paddingRight: "10px" }}
              >
                <img className="fot-logo" src={Logo} />
              </Link>
            </MDBCol>
            <MDBCol md="4" sm="12" xs="12">
              <div className="box">
                <Link
                  target="_blank"
                  to="https://start.vicreation.de/data-privacy/"
                  style={{ paddingRight: "10px" }}
                >
                  Datenschutzerklärung{" "}
                </Link>

                <Link
                  target="_blank"
                  to="https://vicreation.de/en/Impressum/"
                  style={{
                    borderLeft: "1px #fff solid",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                  }}
                >
                  Impressum{" "}
                </Link>
                <ul>
                  <li className="list-unstyled">
                    <a
                      href="https://fb.me/Vicreationde"
                      target="_blank"
                      className="fb-ic mr-3"
                    >
                      <MDBIcon fab icon="facebook-f" />
                    </a>
                  </li>
                  <li className="list-unstyled">
                    <a
                      href="https://twitter.com/vicreationde"
                      target="_blank"
                      className="tw-ic mr-3"
                    >
                      <MDBIcon fab icon="twitter" />
                    </a>
                  </li>
                  <li className="list-unstyled">
                    <a
                      href="https://www.instagram.com/vicreation.de/"
                      target="_blank"
                      className="ins-ic mr-3"
                    >
                      <MDBIcon fab icon="instagram" />
                    </a>
                  </li>
                  <li className="list-unstyled">
                    <a
                      href="https://www.linkedin.com/company/vicreation"
                      target="_blank"
                      className="li-ic mr-3"
                    >
                      <MDBIcon fab icon="linkedin-in" />
                    </a>
                  </li>
                  <li className="list-unstyled">
                    <a
                      href="https://www.youtube.com/channel/UCcYf7vs3ijDjBwRbNeZogyQ"
                      target="_blank"
                      className="yt-ic mr-3"
                    >
                      <MDBIcon fab icon="youtube" />
                    </a>
                  </li>
                </ul>
              </div>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </footer>
      <footer className="mobile">
        <MDBContainer>
          <MDBRow>
            <MDBCol md="4" sm="12" xs="12">
              <div className="box">
                <Link
                  target="_blank"
                  to="https://start.vicreation.de/data-privacy/"
                >
                  Datenschutzerklärung{" "}
                </Link>

                <Link target="_blank" to="https://vicreation.de/en/Impressum/">
                  Impressum{" "}
                </Link>
                <ul>
                  <li className="list-unstyled">
                    <a
                      href="https://fb.me/Vicreationde"
                      target="_blank"
                      className="fb-ic mr-3"
                    >
                      <MDBIcon fab icon="facebook-f" />
                    </a>
                  </li>
                  <li className="list-unstyled">
                    <a
                      href="https://twitter.com/vicreationde"
                      target="_blank"
                      className="tw-ic mr-3"
                    >
                      <MDBIcon fab icon="twitter" />
                    </a>
                  </li>
                  <li className="list-unstyled">
                    <a
                      href="https://www.instagram.com/vicreation.de/"
                      target="_blank"
                      className="ins-ic mr-3"
                    >
                      <MDBIcon fab icon="instagram" />
                    </a>
                  </li>
                  <li className="list-unstyled">
                    <a
                      href="https://www.linkedin.com/company/vicreation"
                      target="_blank"
                      className="li-ic mr-3"
                    >
                      <MDBIcon fab icon="linkedin-in" />
                    </a>
                  </li>
                  <li className="list-unstyled">
                    <a
                      href="https://www.youtube.com/channel/UCcYf7vs3ijDjBwRbNeZogyQ"
                      target="_blank"
                      className="yt-ic mr-3"
                    >
                      <MDBIcon fab icon="youtube" />
                    </a>
                  </li>
                </ul>
              </div>
            </MDBCol>
            <MDBCol md="4" sm="12" xs="12">
              <img className="fot-logo" src={Logo} />
            </MDBCol>

            <MDBCol md="4" sm="12" xs="12">
              <MDBTypography tag="p">©2021 Copyright Vicreation</MDBTypography>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </footer>
    </div>
  )
}

export default Footer
