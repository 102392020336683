import React, { Component } from "react"
import { MDBNavbar, MDBNavbarBrand, MDBContainer, MDBRow } from "mdbreact"
import { Link } from "gatsby"
import Logo from "../../resources/images/Logo.svg"
export default class Header extends Component {
  state = {
    collapse1: false,
    collapseID: "",
  }

  toggleCollapse = collapseID => () => {
    this.setState(prevState => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : "",
    }))
  }

  toggleSingleCollapse = collapseId => {
    this.setState({
      ...this.state,
      [collapseId]: !this.state[collapseId],
    })
  }
  componentDidMount() {
    window.addEventListener("scroll", () => {
      let activeClass = "normal"
      if (window.scrollY > 0) {
        activeClass = "top-nav-collapse"
      }
      this.setState({ activeClass })
    })
    console.log(this.props)
  }
  render() {
    const lng = this.props.lng

    return (
      <MDBContainer fluid>
        <MDBRow>
          <MDBNavbar
            fixed="top"
            className={`scrolling-navbar ${this.state.activeClass}`}
          >
            <Link style={{ margin: "auto" }}  target="_blank" to="https://vicreation.de/">
              <MDBNavbarBrand className="logo">
                <img
                  src={Logo}
                  style={{ width: "200px" }}
                  alt="vicreation logo"
                />
              </MDBNavbarBrand>
            </Link>
          </MDBNavbar>
        </MDBRow>
      </MDBContainer>
    )
  }
}
